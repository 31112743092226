export const state = () => ({
	timeZones: [
		{ timezone: "Etc/GMT+12", name: "(GMT-12:00) Etc/GMT+12" },
		{ timezone: "Pacific/Midway", name: "(GMT-11:00) Pacific/Midway" },
		{ timezone: "Pacific/Samoa", name: "(GMT-11:00) Pacific/Samoa" },
		{ timezone: "Pacific/Honolulu", name: "(GMT-10:00) Pacific/Honolulu" },
		{ timezone: "America/Anchorage", name: "(GMT-09:00) America/Anchorage" },
		{ timezone: "America/Los_Angeles", name: "(GMT-08:00) America/Los_Angeles" },
		{ timezone: "America/Tijuana", name: "(GMT-08:00) America/Tijuana" },
		{ timezone: "America/Phoenix", name: "(GMT-07:00) America/Phoenix" },
		{ timezone: "America/Denver", name: "(GMT-07:00) America/Denver" },
		{ timezone: "America/Chihuahua", name: "(GMT-07:00) America/Chihuahua" },
		{ timezone: "America/Mazatlan", name: "(GMT-07:00) America/Mazatlan" },
		{ timezone: "America/Mexico_City", name: "(GMT-06:00) America/Mexico_City" },
		{ timezone: "America/Monterrey", name: "(GMT-06:00) America/Monterrey" },
		{ timezone: "America/Regina", name: "(GMT-06:00) America/Regina" },
		{ timezone: "America/Chicago", name: "(GMT-06:00) America/Chicago" },
		{ timezone: "America/New_York", name: "(GMT-05:00) America/New_York" },
		{ timezone: "America/Indiana/Indianapolis", name: "(GMT-05:00) America/Indiana/Indianapolis" },
		{ timezone: "America/Bogota", name: "(GMT-05:00) America/Bogota" },
		{ timezone: "America/Lima", name: "(GMT-05:00) America/Lima" },
		{ timezone: "America/Caracas", name: "(GMT-04:30) America/Caracas" },
		{ timezone: "America/Halifax", name: "(GMT-04:00) America/Halifax" },
		{ timezone: "America/La_Paz", name: "(GMT-04:00) America/La_Paz" },
		{ timezone: "America/Santiago", name: "(GMT-04:00) America/Santiago" },
		{ timezone: "America/St_Johns", name: "(GMT-03:30) America/St_Johns" },
		{ timezone: "America/Sao_Paulo", name: "(GMT-03:00) America/Sao_Paulo" },
		{ timezone: "America/Argentina/Buenos_Aires", name: "(GMT-03:00) America/Argentina/Buenos_Aires" },
		{ timezone: "America/Godthab", name: "(GMT-03:00) America/Godthab" },
		{ timezone: "America/Montevideo", name: "(GMT-03:00) America/Montevideo" },
		{ timezone: "Atlantic/South_Georgia", name: "(GMT-02:00) Atlantic/South_Georgia" },
		{ timezone: "Atlantic/Azores", name: "(GMT-01:00) Atlantic/Azores" },
		{ timezone: "Atlantic/Cape_Verde", name: "(GMT-01:00) Atlantic/Cape_Verde" },
		{ timezone: "Africa/Casablanca", name: "(GMT+00:00) Africa/Casablanca" },
		{ timezone: "Europe/Dublin", name: "(GMT+00:00) Europe/Dublin" },
		{ timezone: "Europe/London", name: "(GMT+00:00) Europe/London" },
		{ timezone: "Europe/Lisbon", name: "(GMT+00:00) Europe/Lisbon" },
		{ timezone: "Europe/London", name: "(GMT+00:00) Europe/London" },
		{ timezone: "Africa/Monrovia", name: "(GMT+00:00) Africa/Monrovia" },
		{ timezone: "UTC", name: "(GMT+00:00) UTC" },
		{ timezone: "Europe/Amsterdam", name: "(GMT+01:00) Europe/Amsterdam" },
		{ timezone: "Europe/Belgrade", name: "(GMT+01:00) Europe/Belgrade" },
		{ timezone: "Europe/Berlin", name: "(GMT+01:00) Europe/Berlin" },
		{ timezone: "Europe/Zurich", name: "(GMT+01:00) Europe/Zurich" },
		{ timezone: "Europe/Bratislava", name: "(GMT+01:00) Europe/Bratislava" },
		{ timezone: "Europe/Brussels", name: "(GMT+01:00) Europe/Brussels" },
		{ timezone: "Europe/Budapest", name: "(GMT+01:00) Europe/Budapest" },
		{ timezone: "Europe/Copenhagen", name: "(GMT+01:00) Europe/Copenhagen" },
		{ timezone: "Europe/Ljubljana", name: "(GMT+01:00) Europe/Ljubljana" },
		{ timezone: "Europe/Madrid", name: "(GMT+01:00) Europe/Madrid" },
		{ timezone: "Europe/Paris", name: "(GMT+01:00) Europe/Paris" },
		{ timezone: "Europe/Prague", name: "(GMT+01:00) Europe/Prague" },
		{ timezone: "Europe/Rome", name: "(GMT+01:00) Europe/Rome" },
		{ timezone: "Europe/Sarajevo", name: "(GMT+01:00) Europe/Sarajevo" },
		{ timezone: "Europe/Skopje", name: "(GMT+01:00) Europe/Skopje" },
		{ timezone: "Europe/Stockholm", name: "(GMT+01:00) Europe/Stockholm" },
		{ timezone: "Europe/Vienna", name: "(GMT+01:00) Europe/Vienna" },
		{ timezone: "Europe/Warsaw", name: "(GMT+01:00) Europe/Warsaw" },
		{ timezone: "Africa/Lagos", name: "(GMT+01:00) Africa/Lagos" },
		{ timezone: "Europe/Zagreb", name: "(GMT+01:00) Europe/Zagreb" },
		{ timezone: "Europe/Athens", name: "(GMT+02:00) Europe/Athens" },
		{ timezone: "Europe/Bucharest", name: "(GMT+02:00) Europe/Bucharest" },
		{ timezone: "Africa/Cairo", name: "(GMT+02:00) Africa/Cairo" },
		{ timezone: "Africa/Harare", name: "(GMT+02:00) Africa/Harare" },
		{ timezone: "Europe/Helsinki", name: "(GMT+02:00) Europe/Helsinki" },
		{ timezone: "Europe/Istanbul", name: "(GMT+02:00) Europe/Istanbul" },
		{ timezone: "Asia/Jerusalem", name: "(GMT+02:00) Asia/Jerusalem" },
		{ timezone: "Europe/Kiev", name: "(GMT+02:00) Europe/Kiev" },
		{ timezone: "Africa/Johannesburg", name: "(GMT+02:00) Africa/Johannesburg" },
		{ timezone: "Europe/Riga", name: "(GMT+02:00) Europe/Riga" },
		{ timezone: "Europe/Sofia", name: "(GMT+02:00) Europe/Sofia" },
		{ timezone: "Europe/Tallinn", name: "(GMT+02:00) Europe/Tallinn" },
		{ timezone: "Europe/Vilnius", name: "(GMT+02:00) Europe/Vilnius" },
		{ timezone: "Asia/Baghdad", name: "(GMT+03:00) Asia/Baghdad" },
		{ timezone: "Asia/Kuwait", name: "(GMT+03:00) Asia/Kuwait" },
		{ timezone: "Europe/Minsk", name: "(GMT+03:00) Europe/Minsk" },
		{ timezone: "Africa/Nairobi", name: "(GMT+03:00) Africa/Nairobi" },
		{ timezone: "Asia/Riyadh", name: "(GMT+03:00) Asia/Riyadh" },
		{ timezone: "Europe/Moscow", name: "(GMT+03:00) Europe/Moscow" },
		{ timezone: "Asia/Tehran", name: "(GMT+03:30) Asia/Tehran" },
		{ timezone: "Asia/Muscat", name: "(GMT+04:00) Asia/Muscat" },
		{ timezone: "Asia/Baku", name: "(GMT+04:00) Asia/Baku" },
		{ timezone: "Asia/Muscat", name: "(GMT+04:00) Asia/Muscat" },
		{ timezone: "Asia/Tbilisi", name: "(GMT+04:00) Asia/Tbilisi" },
		{ timezone: "Europe/Volgograd", name: "(GMT+04:00) Europe/Volgograd" },
		{ timezone: "Asia/Yerevan", name: "(GMT+04:00) Asia/Yerevan" },
		{ timezone: "Asia/Kabul", name: "(GMT+04:30) Asia/Kabul" },
		{ timezone: "Asia/Karachi", name: "(GMT+05:00) Asia/Karachi" },
		{ timezone: "Asia/Karachi", name: "(GMT+05:00) Asia/Karachi" },
		{ timezone: "Asia/Tashkent", name: "(GMT+05:00) Asia/Tashkent" },
		{ timezone: "Asia/Kolkata", name: "(GMT+05:30) Asia/Kolkata" },
		{ timezone: "Asia/Colombo", name: "(GMT+05:30) Asia/Colombo" },
		{ timezone: "Asia/Kathmandu", name: "(GMT+05:45) Asia/Kathmandu" },
		{ timezone: "Asia/Almaty", name: "(GMT+06:00) Asia/Almaty" },
		{ timezone: "Asia/Dhaka", name: "(GMT+06:00) Asia/Dhaka" },
		{ timezone: "Asia/Dhaka", name: "(GMT+06:00) Asia/Dhaka" },
		{ timezone: "Asia/Yekaterinburg", name: "(GMT+06:00) Asia/Yekaterinburg" },
		{ timezone: "Asia/Rangoon", name: "(GMT+06:30) Asia/Rangoon" },
		{ timezone: "Asia/Bangkok", name: "(GMT+07:00) Asia/Bangkok" },
		{ timezone: "Asia/Bangkok", name: "(GMT+07:00) Asia/Bangkok" },
		{ timezone: "Asia/Jakarta", name: "(GMT+07:00) Asia/Jakarta" },
		{ timezone: "Asia/Novosibirsk", name: "(GMT+07:00) Asia/Novosibirsk" },
		{ timezone: "Asia/Shanghai", name: "(GMT+08:00) Asia/Shanghai" },
		{ timezone: "Asia/Chongqing", name: "(GMT+08:00) Asia/Chongqing" },
		{ timezone: "Asia/Hong_Kong", name: "(GMT+08:00) Asia/Hong_Kong" },
		{ timezone: "Asia/Krasnoyarsk", name: "(GMT+08:00) Asia/Krasnoyarsk" },
		{ timezone: "Asia/Kuala_Lumpur", name: "(GMT+08:00) Asia/Kuala_Lumpur" },
		{ timezone: "Australia/Perth", name: "(GMT+08:00) Australia/Perth" },
		{ timezone: "Asia/Singapore", name: "(GMT+08:00) Asia/Singapore" },
		{ timezone: "Asia/Taipei", name: "(GMT+08:00) Asia/Taipei" },
		{ timezone: "Asia/Ulaanbaatar", name: "(GMT+08:00) Asia/Ulaanbaatar" },
		{ timezone: "Asia/Urumqi", name: "(GMT+08:00) Asia/Urumqi" },
		{ timezone: "Asia/Irkutsk", name: "(GMT+09:00) Asia/Irkutsk" },
		{ timezone: "Asia/Tokyo", name: "(GMT+09:00) Asia/Tokyo" },
		{ timezone: "Asia/Tokyo", name: "(GMT+09:00) Asia/Tokyo" },
		{ timezone: "Asia/Seoul", name: "(GMT+09:00) Asia/Seoul" },
		{ timezone: "Asia/Tokyo", name: "(GMT+09:00) Asia/Tokyo" },
		{ timezone: "Australia/Adelaide", name: "(GMT+09:30) Australia/Adelaide" },
		{ timezone: "Australia/Darwin", name: "(GMT+09:30) Australia/Darwin" },
		{ timezone: "Australia/Brisbane", name: "(GMT+10:00) Australia/Brisbane" },
		{ timezone: "Australia/Canberra", name: "(GMT+10:00) Australia/Canberra" },
		{ timezone: "Pacific/Guam", name: "(GMT+10:00) Pacific/Guam" },
		{ timezone: "Australia/Hobart", name: "(GMT+10:00) Australia/Hobart" },
		{ timezone: "Australia/Melbourne", name: "(GMT+10:00) Australia/Melbourne" },
		{ timezone: "Pacific/Port_Moresby", name: "(GMT+10:00) Pacific/Port_Moresby" },
		{ timezone: "Australia/Sydney", name: "(GMT+10:00) Australia/Sydney" },
		{ timezone: "Asia/Yakutsk", name: "(GMT+10:00) Asia/Yakutsk" },
		{ timezone: "Pacific/Noumea", name: "(GMT+11:00) Pacific/Noumea" },
		{ timezone: "Pacific/Guadalcanal", name: "(GMT+11:00) Pacific/Guadalcanal" },
		{ timezone: "Asia/Vladivostok", name: "(GMT+11:00) Asia/Vladivostok" },
		{ timezone: "Pacific/Auckland", name: "(GMT+12:00) Pacific/Auckland" },
		{ timezone: "Pacific/Fiji", name: "(GMT+12:00) Pacific/Fiji" },
		{ timezone: "Asia/Kamchatka", name: "(GMT+12:00) Asia/Kamchatka" },
		{ timezone: "Asia/Magadan", name: "(GMT+12:00) Asia/Magadan" },
		{ timezone: "Pacific/Majuro", name: "(GMT+12:00) Pacific/Majuro" },
		{ timezone: "Pacific/Auckland", name: "(GMT+12:00) Pacific/Auckland" },
		{ timezone: "Pacific/Tongatapu", name: "(GMT+13:00) Pacific/Tongatapu" },
		{ timezone: "Pacific/Kiritimati", name: "(GMT+14:00) Pacific/ Kiritimati" }],
	days: [
		{
			text: 'monday',
			value: 'monday',
			index: 0
		},
		{
			text: 'tuesday',
			value: 'tuesday',
			index: 1
		},
		{
			text: 'wednesday',
			value: 'wednesday',
			index: 2
		},
		{
			text: 'thursday',
			value: 'thursday',
			index: 3
		},
		{
			text: 'friday',
			value: 'friday',
			index: 4
		},
		{
			text: 'saturday',
			value: 'saturday',
			index: 5
		},
		{
			text: 'sunday',
			value: 'sunday',
			index: 6
		},
	],
	months: [
		{
			text: 'january',
			value: 1,
			sort: 'jan'
		},
		{
			text: 'february',
			value: 2,
			sort: 'feb'
		},
		{
			text: 'march',
			value: 3,
			sort: 'mar'
		},
		{
			text: 'april',
			value: 4,
			sort: 'apr'
		},
		{
			text: 'may',
			value: 5,
			sort: 'maySort'
		},
		{
			text: 'june',
			value: 6,
			sort: 'jun'
		},
		{
			text: 'july',
			value: 7,
			sort: 'jul'
		},
		{
			text: 'august',
			value: 8,
			sort: 'aug'
		},
		{
			text: 'september',
			value: 9,
			sort: 'sep'
		},
		{
			text: 'october',
			value: 10,
			sort: 'oct'
		},
		{
			text: 'november',
			value: 11,
			sort: 'nov'
		},
		{
			text: 'december',
			value: 12,
			sort: 'dec'
		},

	],
	// for  bank testing
	socket: [],
	daysInWeek: [
		{
			text: 'sunday',
			value: 'sunday',
			index: 0
		},
		{
			text: 'monday',
			value: 'monday',
			index: 1
		},
		{
			text: 'tuesday',
			value: 'tuesday',
			index: 2
		},
		{
			text: 'wednesday',
			value: 'wednesday',
			index: 3
		},
		{
			text: 'thursday',
			value: 'thursday',
			index: 4
		},
		{
			text: 'friday',
			value: 'friday',
			index: 5
		},
		{
			text: 'saturday',
			value: 'saturday',
			index: 6
		},
	],
})
// Mutations
export const mutations = {
	// for bank testing
	setSocket(state, type) {
		state.socket.push(type)
	}
}