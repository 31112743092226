// State
export const state = () => ({
	loading: false,
	domains: [],
	domain: {},
	domainDNS: [],
	cancelToken: {},
})

// Actions
export const actions = {
	async domains({ commit, state }, params) {
		commit('setLoading', true)
		commit('setCancelToken', { currencies: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/website-setting/operator-domain`, { params, cancelToken: state.cancelToken.currencies.token })
			.then((response) => {
				const data = response?.data
				commit('domains', data)
				commit('setLoading', false)
			})
			.catch(() => {
				commit('setLoading', false)
				return false
			})
	},
	async domain({ commit, state }, params) {
		commit('setLoading', true)
		commit('setCancelToken', { currencies: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/website-setting/operator-domain/${params}`, { cancelToken: state.cancelToken.currencies.token })
			.then((response) => {
				const data = response?.data
				commit('domains', data)
				commit('setLoading', false)
			})
			.catch(() => {
				commit('setLoading', false)
				return false
			})
	},
	async domainDNS({ commit, state }, params) {
		commit('setCancelToken', { currencies: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/website-setting/operator-domain/domain/${params}`, { cancelToken: state.cancelToken.currencies.token })
			.then((response) => {
				const data = response?.data
				commit('domainDNS', data)
			})
			.catch(() => {
				return false
			})
	},
	async createDomain({ commit, dispatch }, data) {
		commit('setLoading', true)
		return await this.$axios
			.post(`/website-setting/operator-domain`, data)
			.then((response) => {
				commit('setLoading', false)
				return true;
			})
			.catch(() => {
				commit('setLoading', false)
				return false
			})
	},
	async updateDomain({ commit, dispatch }, data) {
		commit('setLoading', true)
		const form = { ...data }
		const id = form.id;
		delete form.id
		try {
			const response = await this.$axios.put(`/website-setting/operator-domain/${id}`, form).then((response) => {
				return true;
			})
			commit('setLoading', false)
			return response;
		} catch (error) {
			commit('setLoading', false)
			return false
		}
	},
	async deleteDomain({ dispatch }, id) {
		try {
			const response = await this.$axios.delete(`/website-setting/operator-domain/${id}`).then((response) => {
				return true;
			})
			return response;
		} catch (error) {
			return false
		}
	},

}

// Mutations
export const mutations = {
	setLoading(state, data) {
		state.loading = data
	},
	domains(state, response) {
		state.domainDNS = []
		state.domains = response
	},
	domainDNS(state, response) {
		state.domainDNS = response
	},
	setCancelToken: (state, data) => {
		const oldData = { ...state.cancelToken }
		state.cancelToken = {
			...oldData, ...data
		}
	}
}