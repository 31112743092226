
// State
export const state = () => ({
	loading: false,
	companyCryptoWallet: [],
	singleCompanyCryptoWallet: {},
	// cancel token
	financeCancelToken: {},
	logs: {}
})

// Actions
export const actions = {
	async companyCryptoWallets({ commit, state }, params) {
		commit('setFinanceCancelToken', { companyCryptoWallets: this.$axios.CancelToken.source() })

		await this.$axios
			.get('/finances/company-crypto-wallet', {
				params,
				cancelToken: state.financeCancelToken.companyCryptoWallets.token
			})
			.then((response) => {
				commit('setCryptoWallets', response.data)
			})
			.catch(() => false)
	},
	async resetThresholdStatus({ _ }, { id, form }) {
		return await this.$axios
			.put(`/finances/company-crypto-wallet/threshold-status/${id}`, form)
			.then((response) => {
				return true;
			})
			.catch(() => false)
	},
	async cryptoWalletLogs({ commit, state }, id) {
		commit('setFinanceCancelToken', { cryptoWalletLogs: this.$axios.CancelToken.source() })
		const logs = await this.$axios
			.get(`/finances/company-crypto-wallet/logs/${id}`, {
				cancelToken: state.financeCancelToken.cryptoWalletLogs.token
			})
			.then((response) => {
				return response.data;
			})
			.catch(() => {
				return {}
			})
		return logs
	},
	async createWallet({ _ }, form) {
		const response = await this.$axios
			.post(`/finances/company-crypto-wallet`, form)
			.then((response) => {
				return true;
			})
			.catch(() => {
				return false
			})
		return response
	},
	async deleteWallet({ _ }, form) {
		const id = form.id;
		delete form.id;

		const response = await this.$axios
			.delete(`/finances/company-crypto-wallet/${id}`, { data: form })
			.then((response) => {
				return true;
			})
			.catch(() => {
				return false
			})
		return response
	},
	async enableDisableWallet({ _ }, form) {
		const id = form.id;
		delete form.id;
		const response = await this.$axios
			.put(`/finances/company-crypto-wallet/status/${id}`, form)
			.then((response) => {
				return true;
			})
			.catch(() => {
				return false
			})
		return response
	},
	async showBalance({ _ }, id) {
		const response = await this.$axios
			.get(`/finances/company-crypto-wallet/balance/${id}`)
			.then((response) => {
				return response.data;
			})
			.catch(() => {
				return {}
			})
		return response
	},
	async singleCompanyCryptoWallet({ commit }, id) {
		const response = await this.$axios
			.get(`/finances/company-crypto-wallet/${id}`)
			.then((response) => {
				commit('setSingleCompanyCryptoWallet', response.data)
			})
			.catch(() => {
				return false
			})
		return response
	},
	async editCompanyCryptoWallet({ commit }, form) {
		const response = await this.$axios
			.put(`/finances/company-crypto-wallet/${form.id}`, form.body)
			.then((response) => {
				return true;
			})
			.catch(() => {
				return false
			})
		return response
	},
	async walletLogs({ commit, state }, params) {
		commit('setFinanceCancelToken', { walletLogs: this.$axios.CancelToken.source() })
		const response = await this.$axios
			.get(`/finances/wallet-logs`, {
				params,
				cancelToken: state.financeCancelToken.walletLogs.token
			})
			.then((response) => {
				commit('setLogs', response.data)
			})
			.catch(() => {
				return false
			})
		return response
	},

}

// Mutations
export const mutations = {
	loading(state, type) {
		state.loading = type
	},
	setCryptoWallets(state, data) {
		state.companyCryptoWallet = data
	},
	setLogs(state, data) {
		state.logs = data
	},
	setFinanceCancelToken: (state, data) => {
		const oldData = { ...state.financeCancelToken }
		state.financeCancelToken = {
			...oldData, ...data
		}
	},
	setSingleCompanyCryptoWallet: (state, data) => {
		state.singleCompanyCryptoWallet = data
	}
}

// Getters
export const getters = {
	companyCryptoWallet: (state) => {
		const sort = [...state.companyCryptoWallet].sort((a, b) => {
			return new Date(b.created_at) - new Date(a.created_at)
		})
		return sort;
	}
}
